import React, { useEffect, useState } from 'react';
import { Grid, Input, Form } from 'semantic-ui-react';
import CharCard from '../cards/CharCard';
import { stats } from '../../utils/constants'

function CharacterList ({unitData, onClick=() => {}, width=16, filter=true, categories, killList=null, simple=false, showLife=false, size='normal', defaultSort = '', requirement=false, displayDatacron=()=>{}, nicknames={}}){

	useEffect(() => {
		// props.redirect('home')
	})

    const [currentCategory, setCurrentCategory] = useState('')
    const [currentSort, setCurrentSort] = useState(defaultSort)
    const [currentSearch, setCurrentSearch] = useState('')

    const getCategoryOptions = () => {
        return categories
            .filter(category => category.uiFilter.includes(1))
            .sort((a,b) => a.descKey.localeCompare(b.descKey))
            .map(category => {
                return {
                    key: category.id,
                    text: category.descKey,
                    value: category.id
                }
            })
    }

    const getSortOptions = () => {
        let options = ["1", "5", "6", "7", "8", "9", "10", "11", "14", "15", "16", "17", "18", "28"]
        return [
            {
                key: 'power',
                text: 'Power',
                value: 'power'
            },
            {
                key: 'gear',
                text: "Gear and Relics",
                value: 'gear',
                
            },
            {
                key: 'alpha',
                text: 'Alphabetical',
                value: 'alpha'
            },
            ...options.map(option => { return {key: option, text: stats[option].name,value: option}})
        ]
    }

    const sortList = (unitList) => {
        switch(currentSort) {
            case 'gear':
                return unitList.sort((a,b) => b.currentTier - a.currentTier || b.relic.currentTier - a.relic.currentTier)
            case 'alpha':
                return unitList.sort((a,b) => a.nameKey.localeCompare(b.nameKey))
            case 'power':
                return unitList.sort((a,b) => (b.gp || 0) - (a.gp || 0))
            case "1":
            case "5":
            case "6":
            case "7":
            case "8":
            case "9":
            case "10":
            case "11":
            case "14":
            case "15":
            case "16":
            case "17":
            case "18":
            case "28":
                return unitList.sort((a,b) => (b?.stats?.final[currentSort] || 0) - (a?.stats?.final[currentSort] || 0))
            default:
                return unitList
        }
    }

    const handleSortDropdownChange = (e, obj) => {
        let newSort = obj.value
        setCurrentSort(newSort)
    }

    const handleCategoryDropdownChange = (e, obj) => {
        let newCategory = obj.value
        setCurrentCategory(newCategory)
    }

    const handleSearchChange = (e, obj) => {
        let newSearch = obj.value
        setCurrentSearch(newSearch)
    }

    const displayCharacters = () => {

        let search, possibleNicknames, possibleBaseIds
        if(filter) {
            search = currentSearch.trim().toLocaleLowerCase()
            possibleNicknames = nicknames.keys.filter(key => key.includes(search))
            possibleBaseIds = possibleNicknames.map(nickname => nicknames.nicknames[nickname])
        }

        return sortList(unitData)
        .filter(unit => {
            if(filter) {
                // let search = currentSearch.trim().toLocaleLowerCase()
                // let possibleNicknames = nicknames.keys.filter(key => key.includes(search))
                // let possibleBaseIds = possibleNicknames.map(nickname => nicknames.nicknames[nickname])
                return unit.nameKey.toLocaleLowerCase().includes(search) || possibleBaseIds.includes(unit.baseId)
            }
            return true
            
        })
        .filter(unit => currentCategory === '' || unit.categoryId.includes(currentCategory))
        .map((unit, index) => <CharCard disabled={killList && killList[index]} onClick={onClick} key={index} unit={unit} size={size} simple={simple} showLife={showLife} requirement={requirement}/>)
    }

	return <Grid centered>
        {
            filter
            ?
            <Grid.Row>
                <Grid.Column computer={width < 16 ? 16 : 8}>
                    <Form>
                        <Form.Group widths={'equal'}>
                            <Form.Field
                            control={Input}
                                label="Unit Name"
                                placeholder="Unit Name"
                                fluid
                                value={currentSearch}
                                onChange={handleSearchChange}
                            />
                            <Form.Dropdown
                                label="Faction"
                                placeholder="Faction"
                                selection
                                clearable
                                search
                                fluid
                                value={currentCategory}
                                options={getCategoryOptions()}
                                onChange={handleCategoryDropdownChange}
                            />
                            <Form.Dropdown
                                label="Sort"
                                placeholder="Sort"
                                selection
                                clearable
                                search
                                fluid
                                value={currentSort}
                                options={getSortOptions()}
                                onChange={handleSortDropdownChange}
                            />
                        </Form.Group>
                    </Form>
                </Grid.Column>
            </Grid.Row>
        :
        ''
        }
        <Grid.Row centered>
            {displayCharacters()}
            {displayDatacron()}
        </Grid.Row>
	</Grid>
}

export default CharacterList;
